import React, { useState } from 'react';
import { Button, Form, Spinner, Alert } from 'react-bootstrap';
import { generateUniqueNumbers, generateRandomNumbers } from '../../utils/randomUtils';
import TimestampDisplay from '../common/TimestampDisplay';
import './RandomNumberGenerator.css';

const RandomNumberGenerator = ({ simple = false }) => {
  const [min, setMin] = useState(1);
  const [max, setMax] = useState(100);
  const [numToGenerate, setNumToGenerate] = useState(1);
  const [allowRepetition, setAllowRepetition] = useState(false);
  const [sortResults, setSortResults] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [timestamp, setTimestamp] = useState(null);

  const validateInputs = () => {
    if (max < min) {
      setError('Max cannot be less than Min.');
      return false;
    }
    if (numToGenerate > (max - min + 1) && !allowRepetition) {
      setError('Number of numbers to generate cannot be greater than (Max - Min + 1) without repetition.');
      return false;
    }
    return true;
  };

  const generateNumbers = () => {
    if (!validateInputs()) {
      return;
    }
    setLoading(true);
    setNumbers([]);
    setError('');

    setTimeout(() => {
      let newNumbers = allowRepetition
        ? generateRandomNumbers(min, max, numToGenerate)
        : generateUniqueNumbers(min, max, numToGenerate);

      if (sortResults) {
        newNumbers.sort((a, b) => a - b); // Sort numbers in ascending order
      }

      const now = new Date();
      setTimestamp(now);

      setNumbers(newNumbers);
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="random-number-generator">
      <div className="input-tile">
        <h2 className="tile-title">Random Number Generator</h2>
        <div className="selectors">
          <Form.Group controlId="min" className="range-input">
            <Form.Label>Min</Form.Label>
            <Form.Control type="number" value={min} onChange={(e) => setMin(parseInt(e.target.value))} />
          </Form.Group>
          <Form.Group controlId="max" className="range-input">
            <Form.Label>Max</Form.Label>
            <Form.Control type="number" value={max} onChange={(e) => setMax(parseInt(e.target.value))} />
          </Form.Group>
          {!simple && (
            <>
              <Form.Group controlId="numToGenerate">
                <Form.Label>Count</Form.Label>
                <Form.Control type="number" value={numToGenerate} onChange={(e) => setNumToGenerate(parseInt(e.target.value))} min="1" max="50" />
              </Form.Group>
              <Form.Group controlId="allowRepetition">
                <Form.Check
                  type="switch"
                  label="Allow Repetition"
                  checked={allowRepetition}
                  onChange={(e) => setAllowRepetition(e.target.checked)}
                  disabled={numToGenerate <= 1}
                />
              </Form.Group>
              <Form.Group controlId="sortResults">
                <Form.Check
                  type="switch"
                  label="Sort Results"
                  checked={sortResults}
                  onChange={(e) => setSortResults(e.target.checked)}
                  disabled={numToGenerate <= 1}
                />
              </Form.Group>
            </>
          )}
        </div>
        <Button onClick={generateNumbers}>Generate Number{simple || numToGenerate === 1 ? '' : 's'}</Button>
        {loading && <div className="loading-spinner">Generating Number{simple || numToGenerate === 1 ? '' : 's'} ... <Spinner as="span" animation="border" size="sm" /> </div>}
        {error && <Alert variant="danger">{error}</Alert>}
        {simple && numbers.length > 0 && (
          <div className="results-tile">
            <div className="number">{numbers[0]}</div>
            <TimestampDisplay label="Generated at" timestamp={timestamp} />
          </div>
        )}
      </div>
      {!simple && (
        <div className="results">
          {numbers.map((number, index) => (
            <span key={index} className="number">{number}</span>
          ))}
          {timestamp && (
            <TimestampDisplay label="Generated at" timestamp={timestamp} />
          )}
        </div>
      )}
    </div>
  );
};

export default RandomNumberGenerator;
