import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Table, InputGroup } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import TeamForm from './TeamForm';
import OversControl from './OversControl';
import GameFormatControl from './GameFormatControl';
import {
  calculateScorePoints,
  calculateWicketsPoints,
  calculateRunRate,
  calculateOversFromBalls,
  calculateThresholdRunRate,
  calculateTotalPoints
} from '../../../utils/sports/cricket/arclCalculations';
import './SimulationPage.css';

const SimulationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { team1: initialTeam1, team2: initialTeam2, maxOvers: initialMaxOvers, format: initialFormat } = location.state || {};

  const [gameDetails, setGameDetails] = useState({
    maxOvers: initialMaxOvers || 16,
    format: initialFormat || 'regular',
    maxBalls: (initialMaxOvers || 16) * 6,
  });

  const [team1, setTeam1] = useState(initialTeam1 || {
    name: 'Team Batting First',
    score: 0,
    wickets: 0,
    oversPlayed: gameDetails.maxOvers,
    isAllOut: false,
  });

  const [team2, setTeam2] = useState(initialTeam2 || {
    name: 'Chasing Team',
    score: 0,
    wickets: 0,
    oversPlayed: gameDetails.maxOvers,
    isAllOut: false,
  });

  const [wonByRuns, setWonByRuns] = useState(1);
  const [, forceUpdate] = useState();

  useEffect(() => {
    // This effect will run whenever team1's or team2's wickets change
    forceUpdate({});
  }, [team1.wickets, team2.wickets]);

  const handleGameDetailsChange = (field, value) => {
    setGameDetails(prevDetails => ({
      ...prevDetails,
      [field]: value,
      maxBalls: field === 'maxOvers' ? value * 6 : prevDetails.maxBalls,
    }));
  };

  const handleTeamChange = (teamKey, field, value) => {
    const setTeam = teamKey === 'team1' ? setTeam1 : setTeam2;
    setTeam(prevTeam => ({
      ...prevTeam,
      [field]: value,
    }));
    if (field === 'wickets') {
      forceUpdate({});
    }
  };

  const handleWonByRunsChange = (value) => {
    setWonByRuns(Math.max(1, Math.min(6, Number(value))));
  };

  const handleBackToCalculator = () => {
    navigate('/arcl', { 
      state: { 
        team1, 
        team2, 
        maxOvers: gameDetails.maxOvers, 
        format: gameDetails.format 
      } 
    });
  };

  const percentageRanges = [
    { min: 0, max: 50 },
    { min: 50.00000001, max: 60 },
    { min: 60.00000001, max: 70 },
    { min: 70.00000001, max: 80 },
    { min: 80.00000001, max: 90 },
    { min: 90.00000001, max: 100 },
  ];

  const generateScenario1Table = () => {
    const rows = [];

    for (let range of percentageRanges) {
      const fromScore = Math.ceil(team1.score * range.min / 100);
      const toScore = Math.floor(team1.score * range.max / 100);
      const label = `${fromScore} - ${toScore}`;
      
      const row = [label];
      if (gameDetails.format === 'youth') {
        for (let wickets = 0; wickets <= 5; wickets++) {
          const scorePoints = calculateScorePoints(toScore, team1.score, calculateRunRate(toScore, gameDetails.maxBalls), calculateRunRate(team1.score, gameDetails.maxBalls), false);
          const wicketsPoints = calculateWicketsPoints(wickets, wickets === 5, gameDetails.format);
          const totalPoints = calculateTotalPoints(20, scorePoints, wicketsPoints);
          row.push(totalPoints);
        }
      } else {
        const wicketGroups = [0, [1, 2], [3, 4], 5, 6, 7];
        for (let group of wicketGroups) {
          if (Array.isArray(group)) {
            const points = group.map(wickets => {
              const scorePoints = calculateScorePoints(toScore, team1.score, calculateRunRate(toScore, gameDetails.maxBalls), calculateRunRate(team1.score, gameDetails.maxBalls), false);
              const wicketsPoints = calculateWicketsPoints(wickets, wickets === 7, gameDetails.format);
              return calculateTotalPoints(20, scorePoints, wicketsPoints);
            });
            row.push(points[0] === points[1] ? points[0] : `${points[0]}-${points[1]}`);
          } else {
            const scorePoints = calculateScorePoints(toScore, team1.score, calculateRunRate(toScore, gameDetails.maxBalls), calculateRunRate(team1.score, gameDetails.maxBalls), false);
            const wicketsPoints = calculateWicketsPoints(group, group === 7, gameDetails.format);
            const totalPoints = calculateTotalPoints(20, scorePoints, wicketsPoints);
            row.push(totalPoints);
          }
        }
      }
      rows.push(row);
    }

    return rows;
  };

  const generateScenario2Table = () => {
    const rows = [];
    const targetScore = team1.score + wonByRuns;
    const team1RunRate = calculateRunRate(team1.score, gameDetails.maxBalls);
  
    for (let range of percentageRanges) {
      const thresholdRunRate = calculateThresholdRunRate(team1RunRate, range.max);
      
      const toBalls = Math.min(gameDetails.maxBalls, Math.ceil(targetScore / thresholdRunRate * 6) - 1);
      const fromBalls = 1 + (range.min === 0 ? 0 : Math.floor(targetScore / (calculateThresholdRunRate(team1RunRate, range.min) / 6)));
  
      const fromOvers = calculateOversFromBalls(fromBalls);
      const toOvers = calculateOversFromBalls(toBalls);
  
      const label = `${fromOvers.toFixed(1)} - ${toOvers.toFixed(1)}`;
      
      const row = [label];
      if (gameDetails.format === 'youth') {
        for (let wickets = 0; wickets <= 5; wickets++) {
          const chasingTeamRunRate = calculateRunRate(targetScore, fromBalls);
          const scorePoints = calculateScorePoints(team1.score, targetScore, team1RunRate, chasingTeamRunRate, false);
          const wicketsPoints = calculateWicketsPoints(wickets, wickets === 5, gameDetails.format);
          const totalPoints = calculateTotalPoints(20, scorePoints, wicketsPoints);
          row.push(totalPoints);
        }
      } else {
        const wicketGroups = [0, [1, 2], [3, 4], 5, 6, 7];
        for (let group of wicketGroups) {
          if (Array.isArray(group)) {
            const points = group.map(wickets => {
              const chasingTeamRunRate = calculateRunRate(targetScore, fromBalls);
              const scorePoints = calculateScorePoints(team1.score, targetScore, team1RunRate, chasingTeamRunRate, false);
              const wicketsPoints = calculateWicketsPoints(wickets, wickets === 7, gameDetails.format);
              return calculateTotalPoints(20, scorePoints, wicketsPoints);
            });
            row.push(points[0] === points[1] ? points[0] : `${points[0]}-${points[1]}`);
          } else {
            const chasingTeamRunRate = calculateRunRate(targetScore, fromBalls);
            const scorePoints = calculateScorePoints(team1.score, targetScore, team1RunRate, chasingTeamRunRate, false);
            const wicketsPoints = calculateWicketsPoints(group, group === 7, gameDetails.format);
            const totalPoints = calculateTotalPoints(20, scorePoints, wicketsPoints);
            row.push(totalPoints);
          }
        }
      }
      rows.push(row);
    }
  
    return rows;
  };

  const renderTable = (rows, headers, highlightCondition) => (
    <div className="table-responsive">
      <Table striped bordered hover className="simulation-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  className={highlightCondition(rowIndex, cellIndex) ? 'highlighted' : ''}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  const getTableHeaders = (scenario=1) => {
    const leftLabel = scenario === 1 ? 'Chasing Team Score' : 'Chased in Overs';
    if (gameDetails.format === 'youth') {
      return [leftLabel, '0w', '1w', '2w', '3w', '4w', '5w'];
    } else {
      return [leftLabel, '0w', '1-2w', '3-4w', '5w', '6w', '7w'];
    }
  };

  const getHighlightCondition = (isScenario2) => (rowIndex, cellIndex) => {
    const team = isScenario2 ? team2 : team1;
    if (gameDetails.format === 'youth') {
      return cellIndex === team.wickets + 1 && team.wickets !== 0;
    } else {
      const wicketGroups = [0, [1, 2], [3, 4], 5, 6, 7];
      const groupIndex = wicketGroups.findIndex(group => 
        Array.isArray(group) ? group.includes(team.wickets) : group === team.wickets
      );
      if (isScenario2) {
        const row = generateScenario2Table()[rowIndex];
        const [minOvers, maxOvers] = row[0].split(' - ').map(Number);
        return (
          cellIndex === groupIndex + 1 &&
          team.oversPlayed >= minOvers &&
          team.oversPlayed <= maxOvers &&
          team.wickets !== 0 &&
          team.oversPlayed !== 0
        );
      }
      return cellIndex === groupIndex + 1 && team.wickets !== 0;
    }
  };

  return (
    <Container fluid className="simulation-page">
      <h1 className="text-center my-3">ARCL Points Simulation</h1>
      <Button onClick={handleBackToCalculator} className="btn btn-secondary mb-3">Back to Calculator</Button>

      <Row className="mb-3">
        <Col xs={12} md={6}>
          <OversControl
            maxOvers={gameDetails.maxOvers}
            handleMaxOversChange={(e) => handleGameDetailsChange('maxOvers', Number(e.target.value))}
            incrementMaxOvers={() => handleGameDetailsChange('maxOvers', gameDetails.maxOvers + 1)}
            decrementMaxOvers={() => handleGameDetailsChange('maxOvers', Math.max(gameDetails.maxOvers - 1, 0))}
          />
        </Col>
        <Col xs={12} md={6}>
          <GameFormatControl
            format={gameDetails.format}
            handleFormatChange={(e) => handleGameDetailsChange('format', e.target.value)}
          />
        </Col>
      </Row>

      <TeamForm
        team={team1}
        onTeamChange={(_, field, value) => handleTeamChange('team1', field, value)}
        teamNumber={1}
        maxOvers={gameDetails.maxOvers}
      />

        <h2 style={{ color: '#000000', backgroundColor: '#d4e4fa', padding: '10px' }}>
        <span style={{ fontSize: '0.6em', marginLeft: '10px', fontWeight: 'normal' }}>
          If
        </span>
        <span> </span>
        {team1.name} - Wins
        <span style={{ fontSize: '0.6em', marginLeft: '10px', fontWeight: 'normal' }}>
            (batting first)
        </span>
        </h2>
      <p className="mb-3">Number of Wickets lost by {team1.name}</p>
      {renderTable(
        generateScenario1Table(),
        getTableHeaders(1),
        getHighlightCondition(false)
      )}

        <h2 style={{ color: '#000000', backgroundColor: '#d4fad4', padding: '10px' }}>
        <span style={{ fontSize: '0.6em', marginLeft: '10px', fontWeight: 'normal' }}>
          If
        </span>
        <span> </span>
        {team2.name} Wins
        <span style={{ fontSize: '0.6em', marginLeft: '10px', fontWeight: 'normal' }}>
            (chasing)
        </span>
        </h2>
      
      <Row className="align-items-center mb-3">
        <Col xs={12} md="auto">
          <Form.Label className="mb-0">Additional Won By Runs:</Form.Label>
        </Col>
        <Col xs="auto">
          <InputGroup>
            <Button variant="outline-secondary" onClick={() => handleWonByRunsChange(wonByRuns - 1)}>-</Button>
            <Form.Control
              type="number"
              value={wonByRuns}
              onChange={(e) => handleWonByRunsChange(e.target.value)}
              min="1"
              max="6"
              style={{width: '60px', textAlign: 'center'}}
            />
            <Button variant="outline-secondary" onClick={() => handleWonByRunsChange(wonByRuns + 1)}>+</Button>
          </InputGroup>
        </Col>
        <Col xs={12} md="auto">
          <span>+ {team1.score} = {team1.score + wonByRuns} (Team 2 Final Score)</span>
        </Col>
      </Row>
      <p className="mb-3">Number of Wickets lost by {team2.name}</p>
      {renderTable(
        generateScenario2Table(),
        getTableHeaders(2),
        getHighlightCondition(true)
      )}
    </Container>
  );
};

export default SimulationPage;