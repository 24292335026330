import React, { useState, useEffect } from 'react';
import { Button, Form, Table, Spinner } from 'react-bootstrap';
import TimestampDisplay from '../common/TimestampDisplay';
import './DiceRoller.css';

const DiceRoller = () => {
  const [numDice, setNumDice] = useState(1);
  const [results, setResults] = useState([]);
  const [counts, setCounts] = useState({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 });
  const [lastRollTime, setLastRollTime] = useState(null);
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let interval;
    if (lastRollTime) {
      interval = setInterval(() => {
        setCounter(Math.floor((Date.now() - lastRollTime) / 1000));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [lastRollTime]);

  const rollDice = () => {
    setLoading(true);
    setResults([]);
    const outcomes = [];
    const newCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 };

    setTimeout(() => {
      for (let i = 0; i < numDice; i++) {
        const outcome = Math.floor(Math.random() * 6) + 1;
        outcomes.push(outcome);
        newCounts[outcome]++;
      }

      setResults(outcomes);
      setCounts(newCounts);
      setLastRollTime(Date.now());
      setLoading(false);
    }, 1000); // Simulate a delay
  };

  const handleNumDiceChange = (e) => {
    setNumDice(Math.min(parseInt(e.target.value), 10000));
  };

  return (
    <div className="dice-roller">
      <h2>Dice Roller</h2>
      <div className="selectors">
        <Form.Group controlId="numDice">
          <Form.Label>Number of Dice:</Form.Label>
          <Form.Control
            type="number"
            value={numDice}
            onChange={handleNumDiceChange}
            min="1"
            max="50"
          />
        </Form.Group>
      </div>
      <Button onClick={rollDice} disabled={loading}>
        {loading ? 'Rolling...' : `Roll ${numDice === 1 ? 'Die' : 'Dice'}`}
      </Button>
      <div className="results">
        {loading && (
          <div className="loading-spinner">
            Rolling dice... <Spinner as="span" animation="border" size="sm" />
          </div>
        )}
        {!loading && numDice > 1 && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Side</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(counts).map(([side, count]) => (
                <tr key={side}>
                  <td>{side}</td>
                  <td>{count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <div className="rolled-dice">
          {!loading &&
            results.map((result, index) => (
              <div key={index} className="die">
                {result}
              </div>
            ))}
        </div>
        {!loading && lastRollTime && (
          <>
            <TimestampDisplay label="Last Roll" timestamp={lastRollTime} />
            <p>Seconds Since Last Roll: {counter}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default DiceRoller;
