// Helper function to distribute players evenly
const distributeEvenly = (players, teams) => {
  players.forEach((player, index) => {
    teams[index % teams.length].push(player);
  });
};

export const generateBalancedTeams = (players, numTeams) => {
  // Calculate the ideal number of players per team
  const idealTeamSize = Math.ceil(players.length / numTeams);

  // Sort players by overall rating, descending
  const sortedPlayers = [...players].sort((a, b) => parseFloat(b.OverallRating) - parseFloat(a.OverallRating));

  // Initialize teams
  const teams = Array.from({ length: numTeams }, () => []);

  // Separate players into categories
  const topPlayers = sortedPlayers.filter(p => parseFloat(p.OverallRating) >= 4.5);
  const wicketKeepers = sortedPlayers.filter(p => p.is_wicket_keeper !== '0' && !topPlayers.includes(p));
  const lowRatedPlayers = sortedPlayers.filter(p => parseFloat(p.OverallRating) <= 2.5 && !wicketKeepers.includes(p));
  const remainingPlayers = sortedPlayers.filter(p => 
    !topPlayers.includes(p) && !wicketKeepers.includes(p) && !lowRatedPlayers.includes(p)
  );

  // Distribute top players
  distributeEvenly(topPlayers, teams);

  // Distribute wicket keepers
  distributeEvenly(wicketKeepers, teams);

  // Distribute low-rated players
  distributeEvenly(lowRatedPlayers, teams);

  // Distribute remaining players to balance team sizes
  let currentTeamIndex = 0;
  remainingPlayers.forEach(player => {
    // Find the team with the fewest players
    const teamSizes = teams.map(team => team.length);
    const minSize = Math.min(...teamSizes);
    currentTeamIndex = teamSizes.indexOf(minSize);

    teams[currentTeamIndex].push(player);
    currentTeamIndex = (currentTeamIndex + 1) % numTeams;
  });

  // Final balance check
  while (Math.max(...teams.map(t => t.length)) - Math.min(...teams.map(t => t.length)) > 1) {
    const maxTeam = teams.reduce((a, b) => a.length > b.length ? a : b);
    const minTeam = teams.reduce((a, b) => a.length < b.length ? a : b);
    const playerToMove = maxTeam.pop();
    minTeam.push(playerToMove);
  }

  return teams;
};

export const calculateTeamRating = (team) => {
  const overallRating = team.reduce((sum, player) => sum + parseFloat(player.OverallRating), 0);
  const battingRating = team.reduce((sum, player) => sum + parseFloat(player.BattingRating), 0);
  const bowlingRating = team.reduce((sum, player) => sum + parseFloat(player.BowlingRating), 0);
  return { overallRating, battingRating, bowlingRating };
};