import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const TickerButton = ({ pickTicker, loading }) => {
  return (
    <Button
      onClick={pickTicker}
      disabled={loading}
      className="d-block mx-auto btn btn-primary btn-lg"
      style={{ backgroundColor: '#28a745', borderColor: '#28a745' }} // Positive green color
    >
      {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Pick a Stock'}
    </Button>
  );
};

export default TickerButton;
