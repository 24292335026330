import React from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import './ArclStyles.css';

const OversControl = ({ maxOvers, handleMaxOversChange, incrementMaxOvers, decrementMaxOvers }) => (
  <div className="control-container">
    <Form.Group controlId="maxOvers" className="mb-0">
      <Form.Label>Max Overs of the game</Form.Label>
      <InputGroup>
        <Button variant="outline-secondary" onClick={decrementMaxOvers}>-</Button>
        <Form.Control
          type="number"
          value={maxOvers}
          onChange={handleMaxOversChange}
          className="flex-grow-1"
        />
        <Button variant="outline-secondary" onClick={incrementMaxOvers}>+</Button>
      </InputGroup>
    </Form.Group>
  </div>
);

export default OversControl;