import React from 'react';
import { Card, Col } from 'react-bootstrap';

const PointsSummary = ({ team, teamNumber }) => (
  <Col xs={12} md={6}>
    <Card className="mb-4">
      <Card.Header 
        className="text-center" 
        style={{ backgroundColor: teamNumber === 1 ? '#d4e4fa' : '#d4fad4' }}
      >
        {team.name}
      </Card.Header>
      <Card.Body style={{ backgroundColor: teamNumber === 1 ? '#e6f2ff' : '#e6ffe6' }}>
        <p className="text-center">{team.score}/{team.wickets} ({team.oversPlayed} Overs)</p>
        <p className="text-center font-weight-bold">Total Points: {team.pointsHelpers.totalPoints}</p>
        <p className="text-center">Wicket Points: {team.pointsHelpers.wicketsPoints}</p>
        <p className="text-center">Score Points: {team.pointsHelpers.scorePoints}</p>
      </Card.Body>
    </Card>
  </Col>
);

export default PointsSummary;