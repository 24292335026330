// utils/sports/cricket/arclCalculations.js

export const calculateBallsPlayed = (oversPlayed) => {
  const [overs, balls] = oversPlayed.toString().split('.').map(Number);
  return overs * 6 + (balls || 0);
};

export const calculateRunRate = (score, ballsPlayed) => {
  return ballsPlayed ? (score / ballsPlayed) * 6 : 0;
};

export const calculateEffectiveRunRate = (score, ballsPlayed, isAllOut, maxOvers) => {
  const effectiveBallsPlayed = isAllOut ? maxOvers * 6 : ballsPlayed;
  return calculateRunRate(score, effectiveBallsPlayed);
};

export const calculateBasePoints = (result, team1Name, team2Name) => {
  if (result === 'Tie') {
    return 15;
  } else if (result === team1Name || result === team2Name) {
    return 20;
  }
  return 0;
};

// const scorePoints = calculateScorePoints(team1.score, targetScore, team1RunRate, runRate, true);
export const calculateScorePoints = (losingScore, winningScore, losingRunRate, winningRunRate, battingSecond) => {
  let percentage;
  if (battingSecond) {
    percentage = (losingScore / winningScore) * 100;
  } else {
    percentage = (losingRunRate / winningRunRate) * 100;
  }

  if (percentage <= 50) return 0;
  if (percentage <= 60) return 1;
  if (percentage <= 70) return 2;
  if (percentage <= 80) return 3;
  if (percentage <= 90) return 4;
  return 5;
};

export const calculateWicketsPoints = (wicketsLost, isAllOut, format) => {
  if (format === 'youth') {
    if (isAllOut) return 5;
    if (wicketsLost <= 0) return 0;
    if (wicketsLost <= 1) return 1;
    if (wicketsLost <= 2) return 2;
    if (wicketsLost <= 3) return 3;
    if (wicketsLost <= 4) return 4;
    return 5;
  } else {
    if (isAllOut) return 5;
    if (wicketsLost <= 0) return 0;
    if (wicketsLost <= 2) return 1;
    if (wicketsLost <= 4) return 2;
    if (wicketsLost === 5) return 3;
    if (wicketsLost === 6) return 4;
    return 5;
  }
};

// New utility functions

export const calculateBallRate = (score, overs) => {
  return score / (overs * 6);
};

export const calculateOversFromBalls = (balls) => {
  return Math.floor(balls / 6) + (balls % 6) / 10;
};

export const calculateThresholdRunRate = (baseRunRate, percentage) => {
  return baseRunRate / (percentage / 100);
};

export const calculateTotalPoints = (basePoints, scorePoints, wicketsPoints) => {
  return basePoints + (5 - scorePoints) + (5 - wicketsPoints);
};