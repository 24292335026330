import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Collapse } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TeamForm from './TeamForm';
import PointsSummary from './PointsSummary';
import OversControl from './OversControl';
import GameFormatControl from './GameFormatControl';
import {
  calculateBallsPlayed,
  calculateRunRate,
  calculateEffectiveRunRate,
  calculateBasePoints,
  calculateScorePoints,
  calculateWicketsPoints
} from '../../../utils/sports/cricket/arclCalculations';
import './ArclStyles.css';

const ArclPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const regularFormat = { maxOvers: 16, maxWickets: 7 };
  const youthFormat = { maxOvers: 12, maxWickets: 5 };
  
  const [format, setFormat] = useState('regular');
  const [maxOvers, setMaxOvers] = useState(regularFormat.maxOvers);
  const [maxWickets, setMaxWickets] = useState(regularFormat.maxWickets);

  const [team1, setTeam1] = useState({
    name: 'Team Batting First',
    score: 0,
    wickets: 0,
    oversPlayed: maxOvers,
    maxOversForTeam: maxOvers,
    maxWicketsForTeam: maxWickets,
    isAllOut: false,
    pointsHelpers: {},
  });

  const [team2, setTeam2] = useState({
    name: 'Chasing Team',
    score: 0,
    wickets: 0,
    oversPlayed: maxOvers,
    maxOversForTeam: maxOvers,
    maxWicketsForTeam: maxWickets,
    isAllOut: false,
    pointsHelpers: {},
  });

  const [battingFirst, setBattingFirst] = useState('Team Batting First');
  const [result, setResult] = useState('Tie');
  const [showSummary, setShowSummary] = useState(false);

  useEffect(() => {
    console.log('Location state changed:', location.state);
    if (location.state) {
      const { team1: updatedTeam1, team2: updatedTeam2, maxOvers: updatedMaxOvers, format: updatedFormat } = location.state;
      
      if (updatedTeam1) {
        setTeam1(prevTeam => ({
          ...prevTeam,
          ...updatedTeam1,
          maxOversForTeam: updatedMaxOvers || prevTeam.maxOversForTeam,
          maxWicketsForTeam: updatedFormat === 'youth' ? youthFormat.maxWickets : regularFormat.maxWickets,
        }));
      }
      
      if (updatedTeam2) {
        setTeam2(prevTeam => ({
          ...prevTeam,
          ...updatedTeam2,
          maxOversForTeam: updatedMaxOvers || prevTeam.maxOversForTeam,
          maxWicketsForTeam: updatedFormat === 'youth' ? youthFormat.maxWickets : regularFormat.maxWickets,
        }));
      }
      
      if (updatedMaxOvers) {
        setMaxOvers(updatedMaxOvers);
      }
      
      if (updatedFormat) {
        setFormat(updatedFormat);
        setMaxWickets(updatedFormat === 'youth' ? youthFormat.maxWickets : regularFormat.maxWickets);
      }
    }
  }, [location.state]);

  const handleFormatChange = (e) => {
    const selectedFormat = e.target.value;
    setFormat(selectedFormat);

    const newMaxOvers = selectedFormat === 'youth' ? youthFormat.maxOvers : regularFormat.maxOvers;
    const newMaxWickets = selectedFormat === 'youth' ? youthFormat.maxWickets : regularFormat.maxWickets;

    setMaxOvers(newMaxOvers);
    setMaxWickets(newMaxWickets);

    setTeam1(prevTeam => ({
      ...prevTeam,
      oversPlayed: Math.min(prevTeam.oversPlayed, newMaxOvers),
      maxOversForTeam: newMaxOvers,
      maxWicketsForTeam: newMaxWickets,
    }));

    setTeam2(prevTeam => ({
      ...prevTeam,
      oversPlayed: Math.min(prevTeam.oversPlayed, newMaxOvers),
      maxOversForTeam: newMaxOvers,
      maxWicketsForTeam: newMaxWickets,
    }));
  };

  const handleTeamChange = (team, field, value) => {
    const setTeam = team === 'team1' ? setTeam1 : setTeam2;
    setTeam(prevTeam => {
      const newTeam = { ...prevTeam, [field]: value };
      if (field === 'wickets') {
        newTeam.isAllOut = Number(value) >= maxWickets;
      }
      if (['score', 'wickets', 'oversPlayed'].includes(field)) {
        updateResult(newTeam, team === 'team1' ? team2 : team1);
      }
      return newTeam;
    });
  };

  const updateResult = (team1, team2) => {
    if (team1.score === team2.score) {
      setResult('Tie');
    } else if (team1.score > team2.score) {
      setResult(team1.name);
    } else {
      setResult(team2.name);
    }
  };

  const computePointsHelpers = (team, opponent) => {
    const ballsPlayed = calculateBallsPlayed(team.oversPlayed);
    const runRate = calculateRunRate(team.score, ballsPlayed);
    const effectiveBallsPlayed = team.isAllOut ? maxOvers * 6 : ballsPlayed;
    const effectiveRunRate = calculateEffectiveRunRate(team.score, ballsPlayed, team.isAllOut, maxOvers);

    let basePoints, wicketsPoints, scorePoints;

    if (result === 'Tie') {
      basePoints = 15;
      wicketsPoints = 0;
      scorePoints = 0;
    } else {
      basePoints = result === team.name ? 20 : 0;
      const losingTeam = result === team.name ? opponent : team;
      const winningTeam = result === team.name ? team : opponent;
      const losingRunRate = calculateEffectiveRunRate(losingTeam.score, calculateBallsPlayed(losingTeam.oversPlayed), losingTeam.isAllOut, maxOvers);
      const winningRunRate = calculateEffectiveRunRate(winningTeam.score, calculateBallsPlayed(winningTeam.oversPlayed), winningTeam.isAllOut, maxOvers);
      wicketsPoints = calculateWicketsPoints(winningTeam.wickets, winningTeam.isAllOut, format);
      scorePoints = calculateScorePoints(losingTeam.score, winningTeam.score, losingRunRate, winningRunRate, battingFirst === winningTeam.name);

      if (result === team.name) {  // Team is the winning team
        scorePoints = 5 - scorePoints;
        wicketsPoints = 5 - wicketsPoints;
      }
    }

    return {
      ballsPlayed,
      runRate,
      effectiveBallsPlayed,
      effectiveRunRate,
      basePoints,
      wicketsPoints,
      scorePoints,
      totalPoints: basePoints + wicketsPoints + scorePoints,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (showSummary) {
      setShowSummary(false);
    } else {
      const team1Helpers = computePointsHelpers(team1, team2);
      const team2Helpers = computePointsHelpers(team2, team1);
      setTeam1(prevTeam => ({ ...prevTeam, pointsHelpers: team1Helpers }));
      setTeam2(prevTeam => ({ ...prevTeam, pointsHelpers: team2Helpers }));
      setShowSummary(true);
    }
  };

  const handleSimulate = () => {
    navigate('/arcl-simulate-results', { 
      state: { 
        team1, 
        team2,
        maxOvers, 
        format 
      } 
    });
  };

  return (
    <Container>
      <h1 className="text-center my-3">ARCL Points Calculator</h1>
      <p className="text-center text-muted mt-2 mb-4">
        Calculate your team's points with certainty, every time!
      </p>
      <Form onSubmit={handleSubmit}>
        <Collapse in={!showSummary}>
          <div>
            <div className="controls-container mb-3">
              <OversControl
                maxOvers={maxOvers}
                handleMaxOversChange={(e) => setMaxOvers(Number(e.target.value))}
                incrementMaxOvers={() => setMaxOvers(prev => prev + 1)}
                decrementMaxOvers={() => setMaxOvers(prev => Math.max(prev - 1, 0))}
              />
              <GameFormatControl 
                format={format}
                handleFormatChange={handleFormatChange}
              />
            </div>
            <TeamForm team={team1} onTeamChange={handleTeamChange} teamNumber={1} maxOvers={maxOvers} />
            <Button variant="secondary" onClick={handleSimulate} className="w-100 mb-3">
              Simulate Scores
            </Button>
            <TeamForm team={team2} onTeamChange={handleTeamChange} teamNumber={2} maxOvers={maxOvers} />
  
            <Row className="mb-3">
              <Col xs={12}>
                <Form.Group controlId="battingFirst">
                  <Form.Label>Team Batting First</Form.Label>
                  <Form.Control
                    as="select"
                    value={battingFirst}
                    onChange={(e) => setBattingFirst(e.target.value)}
                  >
                    <option value={team1.name}>{team1.name}</option>
                    <option value={team2.name}>{team2.name}</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
    
            <Row className="mb-3">
              <Col xs={12}>
                <Form.Group controlId="result">
                  <Form.Label>Result</Form.Label>
                  <Form.Control
                    as="select"
                    value={result}
                    onChange={(e) => setResult(e.target.value)}
                  >
                    <option value="Tie">Tie</option>
                    <option value={team1.name}>{team1.name} Won</option>
                    <option value={team2.name}>{team2.name} Won</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Collapse>

        <Button variant="primary" type="submit" className="w-100">
          {showSummary ? 'Edit Scores' : 'Submit'}
        </Button>
      </Form>
  
      <Collapse in={showSummary}>
        <Container className="mt-4">
          <Row>
            <PointsSummary team={team1} teamNumber={1} />
            <PointsSummary team={team2} teamNumber={2} />
          </Row>
          <Row className="mt-3">
            <Col>
              <Button variant="secondary" onClick={handleSimulate} className="w-100">
                Simulate Other Possibilities
              </Button>
            </Col>
          </Row>
        </Container>
      </Collapse>
      <p className="text-center text-muted mt-2 mb-4">
        Please email <Link to="mailto:help@yolopicker.com">help@yolopicker.com</Link> to report any issues.
      </p>
    </Container>
  );
};

export default ArclPage;