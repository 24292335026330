import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import RandomNumberGenerator from '../random/RandomNumberGenerator';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <h2>Welcome to Yolo Picker</h2>
      <p>Your random picker buddy</p>
      <div className="nav-links">
        <RandomNumberGenerator simple={true} />
        <Button as={Link} to="/arcl" className="home-link">ARCL Points Calculator</Button>
        <Button as={Link} to="/coin-flipper" className="home-link">Coin Flipper</Button>
        <Button as={Link} to="/lottery-picker" className="home-link">Lottery Ticket Generator</Button>
        <Button as={Link} to="/dice-roller" className="home-link">Dice Roller</Button>
        <Button as={Link} to="/stock-picker" className="home-link">Stock Picker</Button>
        <Button as={Link} to="/random-number-generator" className="home-link">Random Number Generator</Button>
        
      </div>
    </div>
  );
};

export default Home;

