import React from 'react';
import { Alert } from 'react-bootstrap';

const TickerDisplay = ({ ticker }) => {
  if (!ticker) {
    return null;
  }

  return (
    <Alert variant="success">
      <h2>Picked Stock: {ticker.ticker}</h2>
      <p><b>{ticker.title}</b></p>
      <a href={`https://finance.yahoo.com/quote/${ticker.ticker}`} target="_blank" rel="noopener noreferrer">
        View on Yahoo Finance
      </a>
      <p> Good Luck! </p>
    </Alert>
  );
};

export default TickerDisplay;
