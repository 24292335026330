import React, { useState, useEffect } from 'react';
import { Button, Form, Table, Spinner } from 'react-bootstrap';
import TimestampDisplay from '../common/TimestampDisplay';
import './CoinFlipper.css';

const CoinFlipper = () => {
  const [numCoins, setNumCoins] = useState(1);
  const [results, setResults] = useState([]);
  const [counts, setCounts] = useState({ heads: 0, tails: 0 });
  const [lastFlipTime, setLastFlipTime] = useState(null);
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let interval;
    if (lastFlipTime) {
      interval = setInterval(() => {
        setCounter(Math.floor((Date.now() - lastFlipTime) / 1000));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [lastFlipTime]);

  const flipCoins = () => {
    setLoading(true);
    setResults([]);
    const outcomes = [];
    let heads = 0;
    let tails = 0;

    setTimeout(() => {
      for (let i = 0; i < numCoins; i++) {
        const outcome = Math.random() < 0.5 ? 'Heads' : 'Tails';
        outcomes.push(outcome);
        if (outcome === 'Heads') heads++;
        else tails++;
      }

      setResults(outcomes);
      setCounts({ heads, tails });
      setLastFlipTime(Date.now());
      setLoading(false);
    }, 1000); // Simulate a delay
  };

  const handleNumCoinsChange = (e) => {
    setNumCoins(Math.min(parseInt(e.target.value), 2000)); // limit to 10000
  };

  return (
    <div className="coin-flipper">
      <h2>Coin Flipper</h2>
      <div className="selectors">
        <Form.Group controlId="numCoins">
          <Form.Label>Number of Coins:</Form.Label>
          <Form.Control
            type="number"
            value={numCoins}
            onChange={handleNumCoinsChange}
            min="1"
            max="50"
          />
        </Form.Group>
      </div>
      <Button onClick={flipCoins} disabled={loading}>
        {loading ? 'Flipping...' : `Flip ${numCoins === 1 ? 'Coin' : 'Coins'}`}
      </Button>
      <div className="results">
        {loading && (
          <div className="loading-spinner">
            Flipping coins... <Spinner as="span" animation="border" size="sm" />
          </div>
        )}
        {!loading && results.length > 0 && (
          <>
            {numCoins > 1 && (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Outcome</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Heads</td>
                    <td>{counts.heads}</td>
                  </tr>
                  <tr>
                    <td>Tails</td>
                    <td>{counts.tails}</td>
                  </tr>
                </tbody>
              </Table>
            )}
            <div className="flipped-coins">
              {results.map((result, index) => (
                <div key={index} className={`coin ${result.toLowerCase()}`}>
                  {result}
                </div>
              ))}
            </div>
            <TimestampDisplay label="Last Flip" timestamp={lastFlipTime} />
            <p>Seconds Since Last Flip: {counter}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default CoinFlipper;
