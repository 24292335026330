import React, { useState, useEffect } from 'react';
import { Button, Spinner, Form } from 'react-bootstrap';
import Papa from 'papaparse';
import lotteryGamesData from '../../data/yolo-picker-lottery-games.csv';
import './LotteryPicker.css';
import TimestampDisplay from '../common/TimestampDisplay';

const LotteryPicker = () => {
  const [countries, setCountries] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('USA');
  const [selectedGame, setSelectedGame] = useState('');
  const [gameDetails, setGameDetails] = useState(null);
  const [lotteryNumbers, setLotteryNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [numTickets, setNumTickets] = useState(2);
  const [lastFlipTime, setLastFlipTime] = useState(null);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    Papa.parse(lotteryGamesData, {
      download: true,
      header: true,
      complete: (results) => {
        const data = results.data;
        const uniqueCountries = [...new Set(data.map(item => item.Country))];
        setCountries(uniqueCountries);

        const countryGames = data.filter(item => item.Country === selectedCountry);
        setGames(countryGames);

        if (countryGames.length > 0) {
          setSelectedGame(countryGames[0].Game);
          setGameDetails(countryGames[0]);
        }
      },
    });
  }, [selectedCountry]);

  useEffect(() => {
    let interval;
    if (lastFlipTime) {
      interval = setInterval(() => {
        setCounter(Math.floor((Date.now() - lastFlipTime) / 1000));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [lastFlipTime]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleGameChange = (e) => {
    const selectedGame = games.find(game => game.Game === e.target.value);
    setSelectedGame(selectedGame.Game);
    setGameDetails(selectedGame);
  };

  const handleNumTicketsChange = (e) => {
    setNumTickets(Math.min(parseInt(e.target.value), 50));
  };

  const generateLotteryNumbers = () => {
    if (!gameDetails) return;
    setLoading(true);
    setLotteryNumbers([]);

    setTimeout(() => {
      const tickets = [];
      for (let i = 0; i < numTickets; i++) {
        const {
          allow_repetition,
          first_range_from,
          first_range_to,
          first_range_npicks,
          first_allow_sort,
          second_range_from,
          second_range_to,
          second_range_npicks,
          second_allow_sort,
        } = gameDetails;

        const firstRangeNumbers = allow_repetition === '0'
          ? generateUniqueNumbers(parseInt(first_range_from), parseInt(first_range_to), parseInt(first_range_npicks), first_allow_sort)
          : generateRandomNumbers(parseInt(first_range_from), parseInt(first_range_to), parseInt(first_range_npicks));

        const secondRangeNumbers = second_range_npicks
          ? generateUniqueNumbers(parseInt(second_range_from), parseInt(second_range_to), parseInt(second_range_npicks), second_allow_sort)
          : [];

        tickets.push({ firstRangeNumbers, secondRangeNumbers });
      }
      setLotteryNumbers(tickets);
      setLastFlipTime(Date.now());
      setLoading(false);
    }, 2000);
  };

  const generateUniqueNumbers = (from, to, npicks, allowSort) => {
    const numbers = new Set();
    while (numbers.size < npicks) {
      const number = Math.floor(Math.random() * (to - from + 1)) + from;
      numbers.add(number);
    }
    const numArray = Array.from(numbers);
    return allowSort ? numArray.sort((a, b) => a - b) : numArray;
  };

  const generateRandomNumbers = (from, to, npicks) => {
    const numbers = [];
    for (let i = 0; i < npicks; i++) {
      const number = Math.floor(Math.random() * (to - from + 1)) + from;
      numbers.push(number);
    }
    return numbers;
  };

  return (
    <div className="lottery-picker">
      <h2>Lottery Picker</h2>
      <div className="selectors">
        <Form.Group controlId="country">
          <Form.Label>Country:</Form.Label>
          <Form.Control as="select" value={selectedCountry} onChange={handleCountryChange}>
            {countries.map(country => (
              <option key={country} value={country}>{country}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="game">
          <Form.Label>Game:</Form.Label>
          <Form.Control as="select" value={selectedGame} onChange={handleGameChange}>
            {games.map(game => (
              <option key={game.Game} value={game.Game}>{game.GameDisplayName}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="numTickets">
          <Form.Label>Number of Tickets:</Form.Label>
          <Form.Control
            type="number"
            value={numTickets}
            onChange={handleNumTicketsChange}
            min="1"
            max="50"
          />
        </Form.Group>
      </div>
      <Button onClick={generateLotteryNumbers}>Generate {numTickets === 1 ? 'Ticket' : 'Tickets'}</Button>
      {loading && <div className="loading-spinner">Generating {numTickets === 1 ? 'Ticket' : 'Tickets'} ... <Spinner as="span" animation="border" size="sm" /> </div>}
      <div className="lottery-tickets">
        {lotteryNumbers.length > 0 && lotteryNumbers.map((ticket, ticketIndex) => (
          <div key={ticketIndex} className="ticket-wrapper">
            <div className="ticket-number">{`Ticket ${ticketIndex + 1}`}</div>
            <div className="ticket">
              {ticket.firstRangeNumbers.map((number, index) => (
                <span key={index} className="number-ball first-range">{number}</span>
              ))}
              {ticket.secondRangeNumbers.map((number, index) => (
                <span key={index} className="number-ball second-range">{number}</span>
              ))}
            </div>
          </div>
        ))}
        {lotteryNumbers.length > 0 && (
          <>
            <TimestampDisplay label="Last Generated at:" timestamp={lastFlipTime} />
            <p>Seconds Since Last Ticket Generation: {counter}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default LotteryPicker;
