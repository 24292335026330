import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../assets/yolo.png'; // Ensure the logo file exists in this path

const Header = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="Yolo Picker Logo" style={{ height: '50px', marginRight: '10px' }} />
          Yolo Picker
        </Navbar.Brand>
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/coin-flipper">Flip a Coin</Nav.Link>
          <Nav.Link as={Link} to="/stock-picker">Stock Picker</Nav.Link>
          <Nav.Link as={Link} to="/arcl">ARCL Points</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
