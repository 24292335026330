export const generateUniqueNumbers = (from, to, npicks, allowSort) => {
    const numbers = new Set();
    while (numbers.size < npicks) {
      const number = Math.floor(Math.random() * (to - from + 1)) + from;
      numbers.add(number);
    }
    const numArray = Array.from(numbers);
    return allowSort ? numArray.sort((a, b) => a - b) : numArray;
  };
  
  export const generateRandomNumbers = (from, to, npicks) => {
    const numbers = [];
    for (let i = 0; i < npicks; i++) {
      const number = Math.floor(Math.random() * (to - from + 1)) + from;
      numbers.push(number);
    }
    return numbers;
  };
  