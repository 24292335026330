import React from 'react';
import { Form } from 'react-bootstrap';
import './ArclStyles.css';

const GameFormatControl = ({ format, handleFormatChange }) => {
  return (
    <div className="control-container">
      <Form.Group controlId="gameFormat" className="mb-0">
        <Form.Label>Game Format</Form.Label>
        <div>
          <Form.Check
            type="radio"
            label="Regular"
            name="gameFormat"
            id="regularFormat"
            value="regular"
            checked={format === 'regular'}
            onChange={handleFormatChange}
            inline
          />
          <Form.Check
            type="radio"
            label="Youth 6 Players"
            name="gameFormat"
            id="youthFormat"
            value="youth"
            checked={format === 'youth'}
            onChange={handleFormatChange}
            inline
          />
        </div>
      </Form.Group>
    </div>
  );
};

export default GameFormatControl;