import React, { useState, useEffect } from 'react';
import TickerButton from './TickerButton';
import TickerDisplay from './TickerDisplay';
import stockData from '../../data/stocklist.json';

const StockPicker = () => {
  const [ticker, setTicker] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredStocks, setFilteredStocks] = useState([]);
  const [hasPicked, setHasPicked] = useState(false);

  useEffect(() => {
    const filtered = Object.values(stockData).slice(0, 2000);
    setFilteredStocks(filtered);
  }, []);

  const pickTicker = () => {
    setLoading(true);
    setTicker(null);
    setHasPicked(true);

    setTimeout(() => {
      const randomIndex = Math.floor(Math.random() * filteredStocks.length);
      setTicker(filteredStocks[randomIndex]);
      setLoading(false);
    }, 500 + Math.floor(Math.random() * 1000));
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <div className="my-4">
        <h1>Welcome to Stock Picker!</h1>
        <p>
          This tool randomly selects a stock. Click the button below to see which stock you get. Please remember, this is just for fun and not financial advice.
        </p>
      </div>
      <div className="my-4">
        <TickerButton pickTicker={pickTicker} loading={loading} />
      </div>
      <div className="my-4">
        <TickerDisplay ticker={ticker} />
      </div>
      {hasPicked && (
        <div style={{ marginTop: '20px', padding: '10px', backgroundColor: '#f8d7da', color: '#721c24', border: '1px solid #f5c6cb', borderRadius: '5px' }}>
          <strong>Disclaimer:</strong> This tool is for entertainment purposes only and should not be considered as financial advice. The information provided here does not constitute investment, financial, or other professional advice. Please consult with a qualified professional before making any financial decisions.
        </div>
      )}
    </div>
  );
};

export default StockPicker;
