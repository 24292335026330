import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, ListGroup } from 'react-bootstrap';
import Papa from 'papaparse';
import teamNamesCSV from './ARCLTeamNames.csv';

const TeamForm = ({ team, onTeamChange, teamNumber, maxOvers }) => {
  const [score, setScore] = useState(team.score || '');
  const [wickets, setWickets] = useState(team.wickets || '');
  const [oversPlayed, setOversPlayed] = useState(team.oversPlayed || '');
  const [teamName, setTeamName] = useState(team.name);
  const [defaultName, setDefaultName] = useState(team.name);
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [teamNames, setTeamNames] = useState([]);
  const [filteredTeamNames, setFilteredTeamNames] = useState([]);
  const [hasBeenEdited, setHasBeenEdited] = useState(false);

  useEffect(() => {
    // Load team names from the 'TeamName' column in the CSV
    Papa.parse(teamNamesCSV, {
      download: true,
      header: true,
      complete: (results) => {
        const names = results.data.map(row => row.TeamName).filter(Boolean);
        setTeamNames(names);
      }
    });
  }, []);

  useEffect(() => {
    onTeamChange(`team${teamNumber}`, 'score', score === '' ? 0 : Number(score));
  }, [score]);

  useEffect(() => {
    onTeamChange(`team${teamNumber}`, 'wickets', wickets === '' ? 0 : Number(wickets));
  }, [wickets]);

  useEffect(() => {
    const validOvers = Math.min(Number(oversPlayed), maxOvers);
    setOversPlayed(validOvers);
    onTeamChange(`team${teamNumber}`, 'oversPlayed', validOvers);
  }, [oversPlayed, maxOvers]);

  const handleTeamNameChange = (e) => {
    const value = e.target.value;
    setTeamName(value);
    setHasBeenEdited(true);

    if (value) {
      const filteredNames = teamNames.filter(name =>
        name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTeamNames(filteredNames);
    } else {
      setFilteredTeamNames([]);
    }
  };

  const handleTeamNameSelect = (name) => {
    setTeamName(name);
    setFilteredTeamNames([]);
    onTeamChange(`team${teamNumber}`, 'name', name);
    setHasBeenEdited(true);
  };

  const handleTeamNameFocus = () => {
    setIsNameFocused(true);
    if (!hasBeenEdited) {
      setTeamName('');
    }
  };

  const handleTeamNameBlur = () => {
    setIsNameFocused(false);
    if (teamName === '') {
      setTeamName(defaultName);
      setHasBeenEdited(false);
    }
  };

  return (
    <Card className="mb-4">
      <Card.Header className="text-center" style={{ backgroundColor: teamNumber === 1 ? '#d4e4fa' : '#d4fad4' }}>
        {teamName}
      </Card.Header>
      <Card.Body style={{ backgroundColor: teamNumber === 1 ? '#e6f2ff' : '#e6ffe6' }}>
        <Row className="mb-3">
          <Col xs={6} className="position-relative">
            <Form.Group controlId={`team${teamNumber}Name`}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={teamName}
                onChange={handleTeamNameChange}
                onFocus={handleTeamNameFocus}
                onBlur={handleTeamNameBlur}
                placeholder="Enter team name"
                style={{
                  color: !hasBeenEdited && teamName === defaultName ? 'gray' : 'black'
                }}
              />
              {filteredTeamNames.length > 0 && (
                <ListGroup className="position-absolute z-index-100 w-100">
                  {filteredTeamNames.map((name, idx) => (
                    <ListGroup.Item
                      key={idx}
                      onClick={() => handleTeamNameSelect(name)}
                      action
                    >
                      {name}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group controlId={`team${teamNumber}Score`}>
              <Form.Label>Score</Form.Label>
              <Form.Control
                type="number"
                value={score}
                onChange={(e) => setScore(e.target.value)}
                onFocus={(e) => e.target.value === '0' && setScore('')}
                onBlur={(e) => e.target.value === '' && setScore(0)}
                placeholder={0}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <Form.Group controlId={`team${teamNumber}Wickets`}>
              <Form.Label>Wickets</Form.Label>
              <Form.Control
                type="number"
                value={wickets}
                onChange={(e) => setWickets(e.target.value)}
                onFocus={(e) => e.target.value === '0' && setWickets('')}
                onBlur={(e) => e.target.value === '' && setWickets(0)}
                placeholder={0}
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group controlId={`team${teamNumber}OversPlayed`}>
              <Form.Label>Overs Played</Form.Label>
              <Form.Control
                type="number"
                value={oversPlayed}
                onChange={(e) => setOversPlayed(e.target.value)}
                onFocus={(e) => e.target.value === '0' && setOversPlayed('')}
                onBlur={(e) => e.target.value === '' && setOversPlayed(0)}
                placeholder="0.0"
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId={`team${teamNumber}AllOut`} className="d-flex align-items-center">
          <Form.Check
            type="checkbox"
            label="All Out (points calculated as 7 wickets lost)"
            checked={team.isAllOut}
            onChange={(e) => onTeamChange(`team${teamNumber}`, 'isAllOut', e.target.checked)}
            className="mr-2"
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default TeamForm;