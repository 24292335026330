export const sortAndGroupPlayers = (players) => {
    return [...players].sort((a, b) => {
      const aLevel = parseInt(a.regular_level, 10);
      const bLevel = parseInt(b.regular_level, 10);
      
      // First, separate into two groups
      if (aLevel >= 3 && bLevel < 3) return -1;
      if (aLevel < 3 && bLevel >= 3) return 1;
      
      // Within each group, sort alphabetically
      const nameA = (a.displayName || a.PlayerName).toLowerCase();
      const nameB = (b.displayName || b.PlayerName).toLowerCase();
      return nameA.localeCompare(nameB);
    });
  };
  
  export const processPlayerData = (playerData) => {
    return playerData.map(player => ({
      ...player,
      isSelected: parseInt(player.regular_level, 10) >= 3
    }));
  };