import React from 'react';
import PropTypes from 'prop-types';
import { Boules, BoxingGlove, TennisBall } from "@phosphor-icons/react";
import './PlayerTile.css';

const PlayerTile = ({ player, onSelect }) => {
  return (
    <div 
      className={`player-tile ${player.isSelected ? 'selected' : ''}`}
      onClick={() => onSelect(player)}
    >
      <div className="player-name">{player.displayName || player.PlayerName}</div>
      <div className="player-icons">
        {player.is_wicket_keeper === '1' && (
          <BoxingGlove size={16} color="#0e816e" weight="fill" className="icon icon-keeper" />
        )}
        {player.bowling_flag === '1' && (
          <Boules size={16} color="#a30505" weight="fill" className="icon icon-bowler" />
        )}
        {['2', '3'].includes(player.bowling_flag) && (
          <TennisBall size={16} color="#f0d738" weight="fill" className="icon icon-bowler" />
        )}
      </div>
    </div>
  );
};

PlayerTile.propTypes = {
  player: PropTypes.shape({
    PlayerName: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    is_wicket_keeper: PropTypes.string.isRequired,
    bowling_flag: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default PlayerTile;