import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PlayerList from './PlayerList';
import { Button, InputNumber } from 'antd';
import { generateBalancedTeams, calculateTeamRating } from '../../../utils/sports/brcl/teamUtils';
import './BrclPage.css';

const BrclPage = () => {
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [numberOfTeams, setNumberOfTeams] = useState(2);
  const navigate = useNavigate();

  const handleSelectionChange = useCallback((selected, all) => {
    setSelectedPlayers(selected);
    setAllPlayers(all);
  }, []);

  const handleMakeTeams = useCallback(() => {
    const teams = generateBalancedTeams(selectedPlayers, numberOfTeams);

    // Calculate and log team ratings
    teams.forEach((team, index) => {
      const ratings = calculateTeamRating(team);
      console.log(`Team ${index + 1} Ratings:`, ratings);
    });

    navigate('/brclmaketeams', { 
      state: { 
        teams: teams.map((team, index) => ({
          id: `team-${index + 1}`,
          name: `Team ${index + 1}`,
          players: team
        })), 
        allPlayers 
      } 
    });
  }, [selectedPlayers, numberOfTeams, navigate, allPlayers]);

  return (
    <div className="brcl-page">
      <h1>BRCL Player Selection</h1>
      <div className="selection-info">
        Selected Players: {selectedPlayers.length}
      </div>
      <PlayerList onSelectionChange={handleSelectionChange} />
      <div className="team-controls">
        <InputNumber
          min={2}
          max={4}
          value={numberOfTeams}
          onChange={setNumberOfTeams}
          addonBefore="Number of Teams"
        />
        <Button 
          type="primary" 
          onClick={handleMakeTeams}
          disabled={selectedPlayers.length < numberOfTeams}
        >
          Make Teams
        </Button>
      </div>
    </div>
  );
};

export default BrclPage;