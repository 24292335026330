// src/components/Common/TimestampDisplay.js

import React from 'react';

const TimestampDisplay = ({ label, timestamp }) => {
  const formatTime = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString();
  };

  const formatUtcTime = (timestamp) => {
    return new Date(timestamp).toUTCString();
  };

  return (
    <div className="timestamp-display">
      <p>{label}:</p>
      <p>(Local Time): {formatTime(timestamp)}</p>
      <p>(UTC Time): {formatUtcTime(timestamp)}</p>
    </div>
  );
};

export default TimestampDisplay;
