import React, { useEffect, useState, useCallback } from 'react';
import Papa from 'papaparse';
import PlayerTile from './PlayerTile';
import { sortAndGroupPlayers, processPlayerData } from '../../../utils/sports/brcl/playerUtils';
import csvFilePath from '../../../data/sports/brcl/brclPlayersProfile.csv';
import './PlayerList.css';

const PlayerList = ({ onSelectionChange }) => {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await fetch(csvFilePath);
        const csvData = await response.text();
        
        Papa.parse(csvData, {
          header: true,
          complete: (result) => {
            const processedPlayers = processPlayerData(result.data);
            setPlayers(processedPlayers);
          },
          error: (error) => {
            console.error('Error parsing CSV:', error);
          }
        });
      } catch (error) {
        console.error('Error fetching CSV:', error);
      }
    };

    fetchPlayers();
  }, []);

  const sortedPlayers = sortAndGroupPlayers(players);

  const handlePlayerSelect = useCallback((selectedPlayer) => {
    setPlayers(prevPlayers =>
      prevPlayers.map(player =>
        player.PlayerName === selectedPlayer.PlayerName
          ? { ...player, isSelected: !player.isSelected }
          : player
      )
    );
  }, []);

  useEffect(() => {
    const selectedPlayers = players.filter(player => player.isSelected);
    onSelectionChange(selectedPlayers, players);
  }, [players, onSelectionChange]);

  return (
    <div className="player-list-container">
      {sortedPlayers.map((player) => (
        <PlayerTile
          key={player.PlayerName}
          player={player}
          onSelect={handlePlayerSelect}
        />
      ))}
    </div>
  );
};

export default PlayerList;